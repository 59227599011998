import React from "react";
import Footer from "../components/Footer/Footer";
import TeammateHeader from "../components/Headers/TeammateHeader/TeammateHeader";
import Navbar from "../components/Navbar/Navbar";

import Background from "../images/teammateBackground.png";
import BackgroundMobile from "../images/teammateBackgroundMobile.png";
import TeammatePhoto from "../images/Swapnil_Photo.png";
import Teammate from "../components/Teammate/Teammate";

const teammatePage = () => {
  return (
    <div>
      <Navbar />
      <TeammateHeader
        Name="Swapnil Narola  "
        Description="Developer"
        background={Background}
        backgroundMobile={BackgroundMobile}
        TeammatePhoto={TeammatePhoto}
      />
      <article className="w-[100%] h-auto flex items-center justify-center bg-white">
        <Teammate
          paragraph1="hi! I am Swapnil Narola, a graphics engineer and technical artist with a focus on game development adept with C, C#, C++, HLSL, and GLSL in conjunction with most modern graphic APIs. I have strong values of teamwork, problem-solving, communication diversity, and consistent punctuality. I’m a lifelong learner and jubilant about working with intermeta – we really are building intermetability technology from the ground up."
          paragraph2="i believe that one prominent function of intermetability could be its implication in virtual work among many other use cases for the technology. We will do this together - testing, learning, adjusting, and innovating so that we can meet our desired outcomes. I am very excited to be on this journey and looking forward to seeing the impact of our innovative product on this industry."
        />
      </article>
      <Footer />
    </div>
  );
};

export default teammatePage;
